var _sentryCollisionFreeGlobalObject = typeof window != "undefined" ? window : typeof global != "undefined" ? global : typeof self != "undefined" ? self : {};
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"BRy2gFZmLiFY_OVKk5G4w"};
_sentryCollisionFreeGlobalObject["__sentryBasePath"] = undefined;
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "";

// This file configures the initialization of Sentry on the browser.
// The config you add here will be used whenever a page is visited.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

import * as Sentry from '@sentry/nextjs'

const SENTRY_DSN = process.env.SENTRY_DSN || process.env.NEXT_PUBLIC_SENTRY_DSN

const isProd = process.env.NEXT_PUBLIC_ENV === 'production'

Sentry.init({
  environment: process.env.NEXT_PUBLIC_ENV,
  release: `${process.env.NEXT_PUBLIC_ENV}@${
    process.env.NEXT_PUBLIC_SHA || ''
  }`,
  beforeSend(event, hint) {
    const originalException = hint.originalException
    if (
      typeof originalException === 'string' &&
      originalException.indexOf('this error can be safely ignored') >= 0
    ) {
      return null
    }
    return event
  },
  dsn:
    SENTRY_DSN ||
    'https://f832b798bf6f418eb23c405cf9d64ca2@o1279780.ingest.sentry.io/4503958492217344',
  // Adjust this value in production, or use tracesSampler for greater control
  sampleRate: 0.1,
  tracesSampler() {
    if (!isProd) return false
    return 0.05
  },
  // ...
  // Note: if you want to override the automatic release value, do not set a
  // `release` value here - use the environment variable `SENTRY_RELEASE`, so
  // that it will also get attached to your source maps
})
